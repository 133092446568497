<template>
  <div class="inner __faq">
    <sub-title
      class="req-type"
      :title="this.communityTitle"
      :description="this.communityDescription"
    >
    </sub-title>
    <div class="sub-cont">
      <div class="faq-tapmenu-wrap">
        <ul style="cursor: pointer">
          <li>
            <router-link
              :class="{ on: routeName === 'requirement' }"
              to="/community"
            >
              자주 묻는 질문
            </router-link>
          </li>
          <li>
            <router-link :class="{ on: routeName === 'notice' }" to="/Notice">
              공지사항
            </router-link>
          </li>
          <li>
            <router-link
              :class="{ on: routeName === 'eventNnews' }"
              to="/EventNnews"
            >
              이벤트&뉴스
            </router-link>
          </li>
          <li>
            <router-link
              :class="{ on: routeName === 'affiliates' }"
              to="/Affiliates"
            >
              제휴 현황
            </router-link>
          </li>
          <li>
            <router-link
              :class="{ on: routeName === 'oneOnOne' }"
              to="/oneOnOne"
            >
              1대1 문의
            </router-link>
          </li>
        </ul>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SubTitle from 'Common/SubTitle'

export default {
  components: { SubTitle },
  data() {
    return {}
  },
  watch: {},
  created() {},
  computed: {
    communityTitle() {
      if (this.$route.name == 'requirement') {
        return '이용문의'
      } else if (this.$route.name == 'notice') {
        return '공지사항'
      } else if (this.$route.name == 'eventNnews') {
        return '이벤트 & 뉴스'
      } else if (this.$route.name == 'affiliates') {
        return '제휴사 현황'
      } else if (this.$route.name == 'oneOnOne') {
        return '1대1문의'
      }
      return undefined
    },
    communityDescription() {
      if (this.$route.name == 'requirement') {
        return '무엇을 도와드릴까요?'
      } else if (this.$route.name == 'notice') {
        return '호구153의 새로운 소식을 확인할 수 있습니다.'
      } else if (this.$route.name == 'eventNnews') {
        return '호구153의 이벤트를 확인할 수 있습니다.'
      } else if (this.$route.name == 'affiliates') {
        return '호구153과 함께하는 제휴사입니다.'
      }
      return undefined
    },
  },
}
</script>

<style>
.btn-save01 {
  width: 300px;
  height: 50px;
  display: flex;
  border-radius: 0.5rem;
  background: #ef7c4a;
  color: #fff;
  font-weight: bold;
  align-items: center;
  margin: 10px auto;
  justify-content: center;
}
.btn-save01 img {
  width: 20px;
  margin-left: 5px;
}
</style>

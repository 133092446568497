<template>
  <div class="tit">
    <h1>{{ title }}</h1>
    <p class="stit">{{ description }}</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
}
</script>

<style></style>
